import React, { useEffect, useState } from "react";
import { Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { getCookie, hasCookie } from "cookies-next";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { serverAPI, ActivityType } from "../utils/envConfig";

const FormModal = ({ showModal, setShowModal, loading }) => {
  const cancelButtonRef = useRef(null);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    if (hasCookie("authuser")) {
      setLoggedIn(true);
      const { first_name, phone, email } = JSON.parse(getCookie("authuser"));
      setName(first_name);
      setPhone(phone);
      setEmail(email);
    }
  }, []);
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (name.trim() !== "" && email.trim() !== "") {
      const payload = JSON.stringify({
        full_name: name,
        email,
        phone,
        message,
        type: ActivityType.AskAQuestion,
      });
      const headers = {
        "Content-Type": "application/json",
      };
      if (hasCookie("authuser")) {
        const { access_token } = JSON.parse(getCookie("authuser")) || "";
        headers["Authorization"] = `Bearer ${access_token}`;
      }
      try {
        await axios.post(`${serverAPI}/contact`, payload, {
          headers,
        });

        toast.success("Submitted");
      } catch (e) {
        toast.error("Error");
      }
    }
  };
  return (
    <>
      {showModal ? (
        <>
          <Transition.Root show={showModal} as={Fragment}>
            <Dialog
              as="div"
              className="fixed z-50 inset-0 overflow-y-auto"
              initialFocus={cancelButtonRef}
              onClose={(e) => setShowModal(false)}
            >
              <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                {/* This element is to trick the browser into centering the modal contents. */}
                <span
                  className="hidden sm:inline-block sm:align-middle sm:h-screen"
                  aria-hidden="true"
                >
                  &#8203;
                </span>
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                  <div className="z-20 absolute top-0 right-0 pt-4 pr-4 sm:block">
                      <button
                        type="button"
                        className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                        onClick={() => setShowModal(false)}
                      >
                        <span className="sr-only">Close</span>
                        <XIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                    <div className="relative h-full">
                <div className="relative h-full">
                    <div className="relative z-10  bg-white flex flex-col rounded-2xl  overflow-hidden h-full ">
                        <div className=" px-4 py-5 sm:px-6">
                            <h3 className="text-2xl text-center font-bold leading-6 text-gray-900">
                            Sell or Rent Property
                            </h3>
                        </div>

                        <form onSubmit={(e) => handleSubmit(e)}
                        className=" grid md:grid-cols-1 gap-2 sm:grid-cols-1">
                            <div className="flex-1 px-4 pt-5 flex flex-col justify-center items-center ">
                            </div>
                            <div
                        className="grid grid-cols-1 gap-y-5 px-4"
                  
                      >
                        <div className="relative block w-full">
                          <input
                            required
                            onChange={(e) => setName(e.target.value)}
                            value={name}
                            type="text"
                            disabled={loggedIn}
                            className="p-2 w-full text-sm text-gray-600 rounded-md focus:border-2  pl-2 border  focus:border-[#CE3A4D] focus:ring-transparent"
                            // placeholder="Name"
                          />
                          <span className="px-2 absolute left-2  -top-2 z-2 text-[10px] font-semibold bg-white z-2">
                            Full Name *
                          </span>
                        </div>
                        <div className="relative block w-full">
                          <input
                            required
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                            disabled={loggedIn}
                            type="text"
                            className="p-2 w-full text-sm text-gray-600 rounded-md focus:border-2  pl-2 border  focus:border-[#CE3A4D] focus:ring-transparent"
                          />
                          <span className="px-2 absolute left-2  -top-2 z-2 text-[10px] font-semibold bg-white z-2">
                            Email *
                          </span>
                        </div>
                        <div className="relative block w-full">
                          <input
                            onChange={(e) => setPhone(e.target.value)}
                            value={phone}
                            disabled={loggedIn}
                            type="text"
                            className="p-2 w-full text-sm text-gray-600 rounded-md focus:border-2  pl-2 border  focus:border-[#CE3A4D] focus:ring-transparent"
                          />
                          <span className="px-2 absolute left-2  -top-2 z-2 text-[10px] font-semibold bg-white z-2">
                            Phone
                          </span>
                        </div>
                        <div>
                          <label htmlFor="message" className="sr-only">
                            Message
                          </label>
                          <div className="relative block w-full">
                            <textarea
                              onChange={(e) => setMessage(e.target.value)}
                              value={message}
                              id="message"
                              name="message"
                              rows={2}
                              className="p-2 w-full text-sm text-gray-600 rounded-md focus:border-2  pl-2 border  focus:border-[#CE3A4D] focus:ring-transparent"
                            />
                            <span className="absolute left-2  -top-2 z-2 text-[10px] font-semibold bg-white z-2 px-2">
                              Message
                            </span>
                          </div>
                        </div>
                        <div className="flex justify-center">
                          <button
                            type="submit"
                            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2  bg-red-500 text-base font-medium text-white hover:bg-red-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:mt-0 sm:w-auto sm:text-sm"
                            // onClick={(e) => handleSubmit(e)}
                          >
                            Submit
                            {loading && (
                              <svg
                                role="status"
                                className="inline mx-1 w-4 h-4 text-gray-200 animate-spin dark:text-gray-600 fill-red-600"
                                viewBox="0 0 100 101"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                  fill="currentColor"
                                />
                                <path
                                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                  fill="currentFill"
                                />
                              </svg>
                            )}
                          </button>
                        </div>
                        </div>
                      </form>

                    </div>
                </div>
            </div>

                  </div>
                </Transition.Child>
              </div>
            </Dialog>
          </Transition.Root>
          <div className="opacity-70 fixed inset-0 z-40 backdrop-blur-[42px] bg-white/40 "></div>
        </>
      ) : null}
    </>
  );
};

export default FormModal;
