import Image from "next/image";
import React from "react";
import { buttonName } from "../utils/envConfig";
const Auction = ( { showForm } ) =>
{
  return (
    <div className="lg:container lg:mx-auto lg:max-w-[930px] xl:max-w-[1200px] md:max-w-[760px] md:mx-auto mx-2 sm:mx-4 mt-10 sm:mt-14 sm:px-4 overflow-hidden 3xl:max-w-[1500px]">
      <div className="relative -z-1 h-full rounded-3xl ">
        <div className="relative flex flex-row h-full 2xl:min-h-[425px] bg-night rounded-[4rem] py-4">
          <div className="md:w-1/2 w-full md:p-[1rem] xl:p-[1rem] mx-10 px-2 py-4 2.5xs:py-8 2xs:py-12 
           sm:p-8lg:tracking-wide md:leading-[50px] lg:leading-[60px]
           xl:leading-[68px] text-white  xl:text[68px] lg:text-[30px]  xl:text-[50px]
            md:text-[34px] 2.5xs:text-3xl text-2xl flex flex-col justify-start
             items-start xl:py-16 2xl:py-20 my-auto">
            <h2 className="font-Lexend font-bold ">
              Rent or Sell your Property with{ " " }
              <span className="inline-block">Alba Homes</span>
            </h2>
            {/* <span>Start listing or </span>
            <span>buying a </span>
            <span>property with</span>
            <span> Alba Homes</span> */}
            <button
              id={ buttonName.letsGetStarted }
              onClick={ showForm }
              className="bg-[#DFC090] md:px-8 lg:px-6 px-4 py-2.5 lg:text-xl text-lg 
               text-night font-Lexend rounded-full font-semibold mt-4 
               hover:bg-night hover:border-[#DFC090] border-2 border-night hover:text-[#FFDEAB] relative block  overflow-hidden mx-auto md:mx-0"
            >
              <h3>{ "Let's Get Started" }</h3>
            </button>
          </div>
          <div className="relative md:w-1/2 flex justify-center items-center">
            <div className="hidden md:block absolute xl:right-[7rem] lg:right-0 md:right-10  -z-2 xl:w-[535px] 
            xl:h-[311px] lg:w-[400px] lg:h-[200px] md:w-[300px] md:h-[260px]">
              <Image
                src="/img/home-svg.svg"
                alt="sell or rent"
                layout="fill"
                objectfit="cover"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Auction;
