import Auction from "../components/Auction";
import { useState, useEffect } from "react";
import FormModal from "../components/FormModal";
import NewListingModal from "../components/NewListingModal";
import ByCommunities from "../components/ByCommunities";
import Head from "next/head";
import { useRouter } from "next/router";
import { webURL, serverAPI } from "../utils/envConfig";
import axios from "axios";
import dynamic from "next/dynamic";
import NodeCache from "node-cache";

const CACHE_EXPIRY_TIME = 30 * 60 * 1000; // 30 minutes in milliseconds
const HomeBanner = dynamic( () => import( "../components/HomeBanner" ), {
  ssr: true,
} );
const References = dynamic( () => import( "../components/References" ), {
  ssr: true,
} );
const Blog = dynamic( () => import( "../components/Blog" ), { ssr: true } );
const Deals = dynamic( () => import( "../components/Deals" ), { ssr: true } );
const Featured = dynamic( () => import( "../components/Featured" ), {
  ssr: true,
} );
const Footer = dynamic( () => import( "../components/Footer" ), { ssr: true } );
const GetMatch = dynamic( () => import( "../components/GetMatch" ), {
  ssr: true,
} );
const Newsletter = dynamic( () => import( "../components/Newsletter" ), {
  ssr: true,
} );

const headers = {
  "Content-Type": "application/json",
};

const Home = ( { initialBlogs, initialDevelopers, errors } ) =>
{
  const router = useRouter();
  const [ blogs, setBlogs ] = useState( initialBlogs );
  const [ developers, setDevelopers ] = useState( initialDevelopers );
  const [ showModal, setShowModal ] = useState( false );
  const [ showFormModal, setShowFormModal ] = useState( false );
  const [ showNewListing, setShowNewListing ] = useState( false );

  useEffect( () =>
  {
    if ( router.pathname === "/new-listing" )
    {
      setShowNewListing( true );
    }

    let cachedBlogs = null;
    let cachedDevelopers = null;

    try
    {
      // Check for cached blogs and developers in localStorage
      cachedBlogs = JSON.parse( localStorage.getItem( "blogsCache" ) );
      cachedDevelopers = JSON.parse( localStorage.getItem( "developersCache" ) );
    } catch ( error )
    {
      console.error( "Error parsing cached data:", error );
    }

    const now = new Date().getTime();

    // If cached blogs exist and are valid, use them, otherwise fetch new data
    if (
      cachedBlogs &&
      cachedBlogs.timestamp &&
      now - cachedBlogs.timestamp < CACHE_EXPIRY_TIME
    )
    {
      setBlogs( cachedBlogs.data );
    } else
    {
      fetchBlogs();
    }

    // If cached developers exist and are valid, use them, otherwise fetch new data
    if (
      cachedDevelopers &&
      cachedDevelopers.timestamp &&
      now - cachedDevelopers.timestamp < CACHE_EXPIRY_TIME
    )
    {
      setDevelopers( cachedDevelopers.data );
    } else
    {
      fetchDevelopers();
    }
  }, [ router.pathname ] );


  const fetchBlogs = async () =>
  {
    try
    {
      const res = await axios.get( `${ serverAPI }/blogs?page=1&limit=3`, {
        withCredentials: true,
      } );
      const blogsData = res.data;
      setBlogs( blogsData );
      localStorage.setItem(
        "blogsCache",
        JSON.stringify( { data: blogsData, timestamp: new Date().getTime() } )
      );
    } catch ( error )
    {
      console.log( "Error fetching blogs:", error );
    }
  };

  const fetchDevelopers = async () =>
  {
    try
    {
      const res = await axios.get( `${ serverAPI }/off-plan/plan-developer` );
      const developersData = res.data;
      setDevelopers( developersData );
      localStorage.setItem(
        "developersCache",
        JSON.stringify( { data: developersData, timestamp: new Date().getTime() } )
      );
    } catch ( error )
    {
      console.log( "Error fetching developers:", error );
    }
  };

  if ( errors )
  {
    console.log( "Error from getting blogs", errors );
  }


  if ( errors )
  {
    console.log( "Error from getting blogs", errors );
  }

  return (
    <div style={ { overflowX: "hidden" } }>
      <Head>
        <link
          rel="canonical"
          href={ `${ webURL }${ router.pathname }` }
          key="canonical"
        />
        <meta
          name="keywords"
          content="Real Estate Company Dubai, Real Estat, Real Estate Agency, Real Estate Company UAE"
        />
        <title>
          Real Estate Company Dubai, UAE | Real Estate Agency | Alba Homes
        </title>
        <meta
          name="description"
          content="Alba Homes is a leading Real Estate Company in Dubai, UAE, offering a wide variety of properties including apartments and villas for buying, selling, or rental."
        />

        <meta
          property="og:title"
          content="Real Estate Company Dubai, UAE | Real Estate Agency | Alba Homes"
        />
        <meta property="og:site_name" content="Alba Homes" />
        <meta property="og:url" content={ webURL + "/" } />
        <meta
          property="og:description"
          content="Alba Homes is a leading Real Estate Company in Dubai, UAE, offering a wide variety of properties including apartments and villas for buying, selling, or rental."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content={ `${ webURL }/img/alba_homes_og_image.webp` }
        />
      </Head>
      <>
        <HomeBanner
          showGetMatched={ () => setShowModal( true ) }
          showNewListingForm={ () => setShowNewListing( true ) }
        />

        <GetMatch showModal={ showModal } setShowModal={ setShowModal } />

        <FormModal showModal={ showFormModal } setShowModal={ setShowFormModal } />

        <NewListingModal
          showNewListing={ showNewListing }
          setShowNewListing={ setShowNewListing }
        />

        <References developers={ developers } />

        <Featured />

        <Auction showForm={ () => setShowNewListing( true ) } />
        <ByCommunities />

        <Deals />

        <Blog blogs={ blogs } />
        <Newsletter />
        <Footer />
      </>
    </div>
  );
};

const cache = new NodeCache( { stdTTL: 86400 } ); // Cache for 24 hours (1 day)

export const getServerSideProps = async ( { req } ) =>
{
  const cachedBlogs = cache.get( 'blogs' );
  const cachedDevelopers = cache.get( 'developers' );

  if ( cachedBlogs && cachedDevelopers )
  {
    return {
      props: {
        blogs: cachedBlogs,
        developers: cachedDevelopers,
      },
    };
  }

  let blogs = [];
  let developers = [];

  if ( req.headers.cookie )
  {
    headers.cookie = req.headers.cookie;
  }

  try
  {
    const res = await axios.get( `${ serverAPI }/blogs?page=1&limit=3`, {
      withCredentials: true,
      headers: {
        ...headers,
      },
    } );

    blogs = res?.data || [];

    try
    {
      const res = await axios.get( `${ serverAPI }/off-plan/plan-developer` );
      if ( res.data.length && res.data.length > 0 )
      {
        developers = res.data.filter( ( e ) => e.logo );
      }
    } catch ( e )
    {
      console.log( e );
    }

    // Store in cache with 1-day TTL
    cache.set( 'blogs', blogs );
    cache.set( 'developers', developers );

    return {
      props: {
        blogs,
        developers,
      },
    };
  } catch ( error )
  {
    return {
      props: {
        blogs: [],
        developers: [],
        errors: JSON.stringify( error ),
      },
    };
  }
};


export default Home;